.ClimateSearchBlock {
  display: flex;
  justify-content: space-between;
  padding: 0;
  min-height: 30rem;
}

.ClimateItemsWrapper {
  flex-grow: 1;
  margin-top: 2rem;
}

.ClimateRiskItem {
  display: flex;
  padding: 0.25rem 1rem;
}

.ClimateRiskItem:not(.disabled) {
  cursor: pointer;
}

.ClimateRiskItem.selected {
  background-color: #ece9fd;
  /* color: #fff; */
}
.ClimateRiskItem:not(.selected):hover {
  background-color: #1243761a;
}

.mapRisk {
  height: 600px;
  flex: 1;
}

.tooltipLabel {
  font-size: 14px;
  font-weight: 600;
}

.tooltipInfo {
  font-size: 14px;
}

.RisksKeyWrapper {
  display: flex;
  flex: 1;
  width: 50%;
  box-shadow: 0px 0px 4px 0px #00000040;
}

.tooltip {
  max-width: 50%;
}
