.StepBarWrapper {
  width: 9rem;
  margin: 0.1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ClimateItemWrapper h5 {
  font-size: 1rem;
}

.StepItem {
  height: 9px;
  width: 9px;
  border-radius: 50%;
}

.StepBarItem {
  flex: 3;
  width: 100%;
  height: 0.2rem;
}

@media screen and (max-width: 1250px) {
  .StepBarWrapper {
    width: 10rem;
  }
}
