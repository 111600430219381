.BusinessItem td.CatnatType {
  display: flex;
  justify-content: center;
}

.catnatButton {
  display: flex;
  width: 100%;
  justify-content: center;
}

.dataCellText {
  text-align: center;
  justify-content: center;
  align-items: center;
}
