.ScaleKeyWrapper {
  padding: 8px;
}

.ScaleKeyitemsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  gap: 10px;
}

.KeyTitle {
  text-transform: uppercase;
  font-size: 14px;
  flex: 1;
}

.KeyItem {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ColorItem {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.LabelItem {
  color: #124376;
  font-size: 0.8rem;
}
