.label-container {
    position: absolute;
    transform-origin: left;
    white-space: nowrap;
    font-size: 14px;
    font-weight: bold;
    color: black;
    background: rgba(255, 255, 255, 0.7);
    padding: 2px 5px;
    border-radius: 4px;
}

#measure-button {
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0,0,0,.3);
    color: rgb(25,25,25);
    cursor: pointer;
    font-family: Roboto,Arial,sans-serif;
    font-size: 16px;
    line-height: 38px;
    margin: 8px 0 22px;
    padding: 0 5px;
    text-align: center;
    margin: 0;
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
}

#measure-button svg {
    stroke-width: 0px;
    fill: #666;
}

#measure-button.active {
    background-color: #33beff;
}

#measure-button.active svg {
    fill: #FFF;
}


#measure-container {
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0,0,0,.3);
    color: rgb(25,25,25);
    cursor: pointer;
    font-family: Roboto,Arial,sans-serif;
    font-size: 18px;
    line-height: 20px;
    margin: 0px 0 16px;
    padding: 0 5px;
    text-align: left;
    position: relative;
    padding: 10px 30px;
    display: none;
}

#measure-container p {
    margin: 8px 0px;
}