.bouncing-loader {
  display: flex;
  justify-content: flex-start;
}

.bouncing-loader > div {
  width: 10px;
  height: 10px;
  margin: 3px 3px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-15px);
  }
}

.bouncing-loader > div:nth-child(2) {
  flex: 1;
}
.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
  flex: 1;
}

.bouncing-loader > div:nth-child(3) {
  flex: 1;
  animation-delay: 0.4s;
}
