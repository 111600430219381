.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.left-arrow, .right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: grey;
    opacity: .7;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-arrow {
    left: 35px;
}

.right-arrow {
    right: 35px;
}

.page-number-wrapper {
    position: absolute;
    bottom: .3rem;
    /* left: 50%; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.pagination-item {
    width: .6rem;
    height: .6rem;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #5387FF;
}

.pagination-item.active{
    width: 1rem;
    height: 1rem;
}

.page-number {
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    text-align: center;
    /* background-color: black; */
}