.AlertEditor {
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    margin-top: 10px;
}

.MapEditor {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    max-height: 80%;
}