.ClimateItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RiskAlert {
  display: flex;
  position: relative;
}

.RiskBarIcon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ClimateItemButton {
  border: none;
  background-color: transparent;
}

.RiskInfos {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.InfosWarning {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
}

.RiskInfos li {
  text-align: center;
  font-size: 0.9rem;
}

.RiskLabel {
  font-weight: 400;
  font-size: 0.75rem;
}

.WarningText {
  text-align: center;
  align-items: center;
  color: red;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  border-radius: 0.5rem;
  width: 100px;
}

.textOriginalRisk {
  padding-left: 40px;
}

.RiskTitle {
  font-size: 0.75rem;
  font-weight: 600;
}
