.AiResultInfosContainer  {
  display: flex;
  flex-direction: column;
  height: 100px;
}

.MainContent {
  display: flex;
  align-items: center;
}

.MiniText {
  align-self: center;
}

.MiniText span {
  font-size: 10px;
}

.MiniText button {
  background-color: transparent;
  border: none;
  font-size: 10px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  color: blue;
  padding: 0;
}

.LabelInfos {
  flex: 4;
}

.ResultInfos {
  flex : 1;
  display: flex;
  justify-content: center;
}

.DisplayResultButton {
  flex: 1;
  display: flex;
  justify-content: center;
}

.RefreshButton {
  background-color: transparent;
  border: none;
  color : red   
}

.VisibilityIcon {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
