.AuthContainer {
  height: 100%;
  background-color: #1e5682;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AuthTextTitle {
  color: #3c5278;
  font-size: 25px;
  font-weight: 600;
}

.AuthTextCopyRight {
  color: #3c5278;
  font-size: 16px;
}

.AuthAlertContainer {
  display: flex;
  justify-content: center;
  width: 320px;
  padding: 10px;
}

.success {
  background-color: #e9f4e9;
  border-top: solid 3px #3f9c35;
}

.error {
  background-color: #f9eaea;
  border-top: solid 3px #d9534f;
}

.warning {
  background-color: #f9f6e7;
  border-top: solid 3px #f0ad4e;
}

.AuthAlertIcon {
  margin-right: 10px;
}

.AuthAlertTextsuccess {
  font-weight: 600;
  color: #1e4f18;
}

.AuthAlertTexterror {
  font-weight: 600;
  color: #d9534f;
}

.AuthAlertTextwarning {
  font-weight: 600;
  color: #f0ad4e;
}
