.WeatherBlockWrapper {
  width: 70% !important;
}

.DatePickerWrapper {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  padding: 2rem 0 1rem 0;
}

.WeatherItemsWrapper {
  border-bottom: 3px solid #EEEEEE;
}

.WeatherItem {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}
