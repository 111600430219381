.Satellite,
.StreetView {
  display: flex;
  justify-content: center;
  position: relative;
  width: 664px;
  height: 664px;
  background-color: #fff;
}

.Satellite .SatelliteImage,
.Satellite .SatelliteImageMask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  padding: 20px;
}

.SatelliteImageMask {
  opacity: 0.3;
  flex: 1;
}

.SatelliteImage img {
  width: 100%;
}

.StreetViewImage {
  box-sizing: border-box;
  position: relative;
}

.StreetViewImage img {
  width: 100%;
  padding: 20px;
  max-width: 700px;
}

.StreetViewImage .StreetViewImageMask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0.5;
  padding: 20px;
  max-width: 700px;
}

.UserNotes {
  padding-left: 2rem;
}

.SatelliteInfos h3 {
  font-size: 24px;
}

.SatelliteInfos .InfosSubTitle {
  margin: 0;
  font-size: 20px;
  font-weight: 100;
}

.PlaceHolder,
.Loader {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PlaceHolder {
  height: 200px;
}

.Loader {
  height: 400px;
}

ul {
  list-style: none;
  padding-inline-start: 0;
}

.InfosSubTitle {
  padding-bottom: 25px;
}

@media screen and (max-width: 1200px) {
  .PlaceHolder {
    height: 500px;
  }
  .MapResultBlock {
    flex-direction: column !important;
  }
  .MapResultBlock ul {
    align-self: center;
  }
}
@media screen and (min-width: 600px) {
  .PhotoItem {
    max-width: 100% !important;
  }
  ul {
    padding-top: 1rem;
  }
}
@media screen and (min-width: 1200px) {
  .PhotoItem {
    max-width: 50% !important;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  padding: 10px 0;
}

.blocModal {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.blocModalDatePickerRange {
  display: flex;
  align-items: center;
}
