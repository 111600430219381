.overlayNorows {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blocText {
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  border-radius: 8px;
}
.noRowText {
  color: #124376;
  text-align: center;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  padding: 50px;
}
.loadingPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
