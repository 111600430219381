.WeatherInfosWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.InfoSectionWrapper{
  padding: 0 1rem;
  height: 14rem;
}

.InfoSectionWrapper h3 {
  margin: .3rem 0;
}

.TextCenter {
  text-align: center
}


.InfoSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.InfoSection  ul  li {
  font-size: .9rem;
}

.GeneralInfos, .SpecificInfos {
  flex: 1
}


.PollutionTable {
  box-sizing: border-box;
  padding: 10px;
  background-color: #FFF;
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.PollutionTable thead {
  background-color: #ECE9FD;
}

.PollutionTable .PollutionInfosItem:nth-child(even) {
  background-color: #F6F6F6;
}

.PollutionTable .PollutionInfosItem th, .PollutionTable .PollutionInfosItem td {
  text-align: center;
  margin: 0;
  padding: 0 0 0 .5rem;
  border: none;
}

.PollutionTable .PollutionInfosItem {
  border-left: solid 1px #EEEEEE;
  border-right: solid 1px #EEEEEE;
  margin: 0;
  height: 30px;
}

.PollutionTable thead .PollutionInfosItem:first-child {
  border-top: solid 1px #EEEEEE;
}

.PollutionTable tbody .PollutionInfosItem:last-child {
  border-bottom: solid 1px #EEEEEE;
}