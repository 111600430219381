.map-container {
  height: 100%;
  width: 100%;
}

.mapboxgl-canvas {
  height: 100%;
  width: 100%;
}

.alertItem {
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.wrapperMap {
  height: 100%;
  display: flex;
}

.mapboxgl-ctrl-geocoder {
  width: 100% !important;
}

.popup {
  display: flex;
  justify-content: space-between;
  min-width: 150px;
  align-items: center;
}

.buttonCreate {
  padding-left: 12px;
}
