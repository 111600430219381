/* rubik-regular - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/rubik/rubik-v23-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/rubik/rubik-v23-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/rubik/rubik-v23-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/rubik/rubik-v23-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/rubik/rubik-v23-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/rubik/rubik-v23-latin-regular.svg#Rubik")
      format("svg"); /* Legacy iOS */
}

html {
  height: 100%;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  background-color: #eee;
}

body {
  margin: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background-color: #eee;
  min-height: 100%;
  height: 100%;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
}

.--textTitleTable {
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 4px;
  padding-top: 10px;
}
